import React from 'react';
import {useOutlet, getOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import AdminSearchStaffModal from '../Components/AdminSearchStaffModal';
import AdminSearchCompanyModal from '../Components/AdminSearchCompanyModal';
import AdminSearchCommitteeModal from '../Components/AdminSearchCommitteeModal';
import AdminSearchCommitteeSummaryModal from '../Components/AdminSearchCommitteeSummaryModal';
import AdminDownloadExcelModal from '../Components/AdminDownloadExcelModal';
import AdminImportExcelModal from '../Components/AdminImportExcelModal';
import AdminCompanyBillingModal from '../Components/AdminCompanyBillingModal';
import AdminUserBillingModal from '../Components/AdminUserBillingModal';
import AdminReassignOrderModal from '../Components/AdminReassignOrderModal';
import AdminOrderModifyPriceModal from '../Components/AdminOrderModifyPriceModal';
import AdminSearchProductPayModal from '../Components/AdminSearchProductPayModal';
import AdminCreateProductSign from '../Components/AdminCreateProductSignFormModal';
import AdminWithdrawModal from '../Components/AdminWithdrawModal';
import AdminStopModal from '../Components/AdminStopModal';
import AdminSearchIntExtModal from '../Components/AdminSearchIntExcModal';
import AdminSearchCrossFeeOrderModal from '../Components/AdminSearchCrossFeeOrderModal';
import AdminSearchAnnualFeeOrderModal from '../Templates/AdminSearchAnnualFeeOrderModal';
import {AdminSearchUserCustomModal} from '../Generators/AdminResource/AdminSelectUserCustomWidget';
import {AdminSelectMeetingModal} from '../Generators/AdminResource/AdminSelectMeetingWidget';
import {AdminSelectReceiveModal} from '../Generators/AdminResource/AdminSelectReceiveWidget';
import {AdminSelectPostModal} from '../Generators/AdminResource/AdminSelectPostWidget';
import {AdminSelectMoraleModal} from '../Generators/AdminResource/AdminSelectMoraleWidget';
import InsuranceEditModal from '../Components/InsuranceEditModal';
import VegetarianEditModal from '../Components/VegetarianEditModal';
import AdminFormMoraleListEditModal from '../Generators/AdminResource/AdminFormMoraleListEditModal';
import AdminFormHistoryEditModal from '../Generators/AdminResource/AdminFormHistoryEditModal';
import AdminCompanyCurrentEditModal from '../Components/AdminCompanyCurrentEditModal';
import AdminMemberPasswordResetModal from '../Components/AdminMemberPassowrdResetModal';
import AdminDescriptionsItemModal from '../Components/AdminDescriptionItemModal';
import AdminOrderSplitModal from '../Components/AdminOrderSplitModal';
import AdminOrderCrossDateModal from '../Components/AdminOrderCrossDateModal';
import AdminStaffPasswordResetModal from '../Components/AdminStaffPasswordResetModal';
import AdminLiveStreamCalcModal from '../Components/AdminLiveStreamCalcModal';
import AdminMuxPermissionApplyModal from '../Components/AdminMuxPermissionApplyModal';
import * as AppActions from '../AppActions';
// import * as UserCacheUtil from '../Utils/UserCacheUtil';
import DataJson from '../../data.json';

const UsersOutlet = getOutlet('users');
const ProductsOutlet = getOutlet('products');
const MoralesOutlet = getOutlet('morales');
const StaffsOutlet = getOutlet('staffs');

export default function useAdminLayoutInit({location}) {
  React.useEffect(() => {
    // trigger only once

    async function fetchAdminGlobalData() {
      AppActions.setLoading(true);
      try {
        let companies = [];
        let users = [];

        if (DataJson.env === 'stg') {
          const limit = 2000;

          //company
          const companyPromiseArray = [];

          const companyInitFetch = await JStorage.fetchDocuments(
            'Company',
            {},
            null,
            {offset: 0, limit: 1},
          );

          if (companyInitFetch.total > 0) {
            const counts = Math.ceil(companyInitFetch.total / limit);

            for (let i = 0; i < counts; i++) {
              const promise = JStorage.fetchDocuments(
                'Company',
                {},
                null,
                {
                  offset: i * limit,
                  limit,
                },
                {
                  id: 1,
                  CONAME: 1,
                  address_city: 1,
                  address_zip_code: 1,
                  address_detail: 1,
                  COPHONE1: 1,
                  COFAX1: 1,
                },
              );
              companyPromiseArray.push(promise);
            }

            const companyRespArray = await Promise.all(companyPromiseArray);

            for (let d = 0; d < counts; d++) {
              companies = [...companies, ...companyRespArray[d].results];
            }
          }
        }
        //users
        const uprs = await JStorage.fetchAllDocuments(
          'user_profile',
          {user_type: {$in: ['member', 'extra_member']}},
          null,
          {
            owner: 1,
            name: 1,
            phone: 1,
            email: 1,
            username: 1,
            id_card_number: 1,
            birthday: 1,
            LAWYER_NO: 1,
            EMAIL2: 1,
          },
        );

        const pprs = await JStorage.fetchAllDocuments(
          'private_profile',
          {},
          null,
          {
            _id: 0,
            owner: 1,
            state: 1,
            substate: 1,
            user_type: 1,
            status: 1,
          },
        );

        for (let i = 0; i < uprs.length; i++) {
          const targetPrivateProfile = pprs.find(
            (ppr) => ppr.owner === uprs[i].owner,
          );
          users.push({...uprs[i], ...targetPrivateProfile});
        }

        //staffs
        const staffs = await JStorage.fetchAllDocuments(
          'admin_user',
          {},
          null,
          {},
        );

        //products
        const products = await JStorage.fetchAllDocuments('product', {}, null, {
          name: 1,
          price: 1,
          session: 1,
          tba_hours: 1,
          labels: 1,
          internal_label: 1,
          is_hold_livestream: 1,
          type: 1,
          non_publish_hour: 1,
        });

        //morales
        const morales = await JStorage.fetchAllDocuments('morale', {}, null, {
          id: 1,
          case_number: 1,
        });

        UsersOutlet.update(users);
        StaffsOutlet.update(staffs);
        ProductsOutlet.update(products);
        MoralesOutlet.update(morales);
      } catch (err) {
        console.warn(err);
      } finally {
        AppActions.setLoading(false);
      }
    }

    fetchAdminGlobalData();
  }, []);

  React.useEffect(() => {
    // trigger when location change
  }, [location]);

  //TODO: user cache layer
  // React.useEffect(() => {
  //   let timer = setInterval(async () => {
  //     await UserCacheUtil.nextFetchUsers();
  //   }, 1000);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  return {
    extraElement: (
      <>
        <AdminSearchStaffModal />
        <AdminSearchCompanyModal />
        <AdminSearchCommitteeModal />
        <AdminSearchCommitteeSummaryModal />
        <AdminSearchIntExtModal />
        <AdminDownloadExcelModal />
        <AdminImportExcelModal />
        <AdminSearchUserCustomModal />
        <AdminSelectMeetingModal />
        <AdminSelectReceiveModal />
        <AdminSelectPostModal />
        <AdminCompanyBillingModal />
        <AdminUserBillingModal />
        <AdminReassignOrderModal />
        <AdminOrderModifyPriceModal />
        <AdminWithdrawModal />
        <AdminStopModal />
        <AdminSearchProductPayModal />
        <AdminSearchCrossFeeOrderModal />
        <AdminSearchAnnualFeeOrderModal />
        <AdminCreateProductSign />
        <AdminSelectMoraleModal />
        <AdminFormMoraleListEditModal />
        <AdminFormHistoryEditModal />
        <AdminCompanyCurrentEditModal />
        <AdminMemberPasswordResetModal />
        <AdminDescriptionsItemModal />
        <AdminOrderSplitModal />
        <AdminOrderCrossDateModal />
        <AdminStaffPasswordResetModal />
        <AdminLiveStreamCalcModal />
        <AdminMuxPermissionApplyModal />
        <InsuranceEditModal />
        <VegetarianEditModal />
      </>
    ),
  };
}
