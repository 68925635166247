import React from 'react';
import {getOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import SearchCompanyModal from '../Components/SearchCompanyModal';
import MoraleQueryModal from '../Components/MoraleQueryModal';

export default function useLayoutInit(props) {
  const {location, pageContext} = props;

  React.useEffect(() => {
    // trigget only once
  }, []);

  React.useEffect(() => {
    // trigger when location change
  }, [location]);

  return {
    extraElement: (
      <>
        <SearchCompanyModal />
        <MoraleQueryModal />
      </>
    ),
  };
}
