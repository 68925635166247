import React from 'react';
import styled from 'styled-components';
import {Button, message, Table, Modal, Input} from 'antd';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';
import {
  getUserStateLabel,
  getUserSubstateLabel,
  getUserTypeLabel,
} from '../../Utils/UserUtil';

export default function AdminSelectUserCustomWidget(props) {
  const [users] = useOutlet('users');
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [fetching, setFetching] = React.useState(false);

  //rjsf write "readOnly", but props get "readonly".
  const readonly = props.readonly === true || props.readOnly === true;

  React.useEffect(() => {
    if (props.value) {
      setSelectedUser(users.find((user) => user.owner === props.value));
    }
  }, [props.value, users]);

  return (
    <Wrapper>
      <div className="info">
        {fetching ? (
          '設定中...'
        ) : !props.value ? (
          '尚未設定會員資料'
        ) : !selectedUser ? (
          props.value
        ) : (
          <div>
            <Button
              type="link"
              title="查看詳情"
              size="small"
              style={{
                maxWidth: 400,
                width: '100%',
                whiteSpace: 'pre-wrap',
                textAlign: 'left',
                paddingLeft: 0,
                fontSize: '0.9rem',
                fontWeight: '400',
              }}
              href={`/admin/users/?action=detail&id=${selectedUser.id}`}>
              {selectedUser.username} {selectedUser.name}{' '}
              {getUserStateLabel(selectedUser.state)}{' '}
              {getUserSubstateLabel(selectedUser.substate)}{' '}
              {getUserTypeLabel(selectedUser.user_type)}
            </Button>
            <div style={{fontSize: '0.85rem'}}>手機：{selectedUser.phone}</div>
            <div style={{fontSize: '0.85rem'}}>信箱：{selectedUser.email}</div>
            <div style={{fontSize: '0.85rem'}}>
              備用信箱：
              {selectedUser.EMAIL2 || '無'}
            </div>
          </div>
        )}
      </div>
      {!readonly && (
        <div>
          <Button
            onClick={() => {
              props.onChange('');
              setSelectedUser(null);
            }}
            style={{margin: 5}}>
            清除
          </Button>

          <Button
            type="primary"
            style={{margin: 5}}
            onClick={() =>
              showAdminSearchUserCustomModal({
                onChange: (owner) => {
                  props.onChange(owner);
                },
              })
            }>
            搜尋
          </Button>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--adminBorderColor);
  border-radius: 4px;
  padding: 15px;

  & > .info {
    margin-right: 15px;
  }
`;

getNewOutlet('admin-search-user-custom-modal', null, {autoDelete: false});

export function showAdminSearchUserCustomModal(data) {
  getOutlet('admin-search-user-custom-modal').update(data);
}

const fetchRecords = async (
  keyword,
  columns,
  paging = {offset: 0, limit: 5},
) => {
  try {
    AppActions.setLoading(true);
    let nextProfiles = [];

    const query = keyword
      ? {
          $or: [
            {id_card_number: {$regex: keyword}},
            {name: {$regex: keyword}},
            {LAWYER_NO: {$regex: keyword}},
            {birthday: {$regex: keyword}},
            {phone: {$regex: keyword}},
            {email: {$regex: keyword}},
            {EMAIL2: {$regex: keyword}},
          ],
        }
      : {};

    const {results: _profiles, total} = await JStorage.fetchDocuments(
      'user_profile',
      query,
      null,
      paging,
    );

    const _private_profiles = await JStorage.fetchAllDocuments(
      'private_profile',
      {
        owner: {$in: _profiles.map((upr) => upr.owner)},
      },
    );

    const _companies = await JStorage.fetchAllDocuments('Company', {
      _id: {
        $in: _profiles
          .filter((it) => !!it.company_current)
          .map((it) => ({$oid: it.company_current})),
      },
    });

    for (let record of _profiles) {
      const targetPrivateProfile = _private_profiles.find(
        (ppr) => ppr.owner === record.owner,
      );
      const targetCompanyCurrent = _companies.find(
        (it) => it.id === record.company_current,
      );

      record = {
        ...record,
        cct: targetCompanyCurrent || {},
        points: targetPrivateProfile?.points || 0,
        provider: targetPrivateProfile?.provider || record?.provider || '',
      };

      nextProfiles = [...nextProfiles, record];
    }

    return {total, results: nextProfiles};
  } catch (err) {
    message.error('發生錯誤。');
  } finally {
    AppActions.setLoading(false);
  }
};

export function AdminSearchUserCustomModal(props) {
  const [data, setData] = useOutlet('admin-search-user-custom-modal');
  const [companies] = useOutlet('companies');
  const [keyword, setKeyword] = React.useState(null);
  const [records, setRecords] = React.useState(null);
  const [_selectedRows, _setSelectedRows] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    total: 0,
    pageSize: 3,
  });

  const columns = [
    {
      key: 'username',
      title: '身分證字號',
      dataIndex: 'username',
      width: 120,
    },
    {
      key: 'name',
      title: '姓名',
      dataIndex: 'name',
      fixed: 'left',
      width: 120,
    },
    {
      key: 'info',
      title: '基本資訊',
      dataIndex: 'info',
      render: (_, r) => {
        return (
          <div>
            <div>
              {getUserStateLabel(r.state)} {getUserSubstateLabel(r.substate)}{' '}
              {r.LAWYER_NO}
            </div>
            <div>{(r.birthday || '').split('T')[0]}</div>
            <div>手機：{r.phone}</div>
            <div>信箱：{r.email}</div>
            <div>備用信箱：{r.EMAIL2 || '無'}</div>
          </div>
        );
      },
    },
    {
      key: 'company_current',
      title: '現在事務所',
      dataIndex: 'company_current',
      render: (_, r) => {
        return (
          <div>
            <div>{r.cct.CONAME || '無'}</div>
            <div>
              地址：{r.company_zip_code || r.cct.address_zip_code}
              {r.company_city || r.cct.address_city}
              {r.company_detail || r.cct.address_detail}
            </div>
            <div>電話：{r.company_phone || r.cct.COPHONE1 || '無'}</div>
            <div>傳真：{r.company_fax || r.cct.COFAX1 || '無'}</div>
          </div>
        );
      },
    },
    {
      key: 'owner',
      title: '',
      dataIndex: 'owner',
      width: 10,
      render: () => null,
    },
    {
      key: '',
      title: '',
      dataIndex: '',
      width: 120,
      render: (_, item) => {
        return (
          <Button
            onClick={() => {
              data.onChange(item.owner);
              onClose();
            }}>
            選擇
          </Button>
        );
      },
    },
  ];

  function onClose() {
    setKeyword(null);
    setRecords(null);
    _setSelectedRows([]);
    setData(null);
  }

  return (
    <Modal
      visible={!!data}
      onCancel={onClose}
      footer={null}
      width={1000}
      closable={false}
      maskClosable={true}
      destroyOnClose={true}>
      <ModalWrapper>
        <Input.Search
          style={{marginBottom: 10}}
          placeholder="請輸入關鍵字，例如：身分證字號、姓名、會員序號、手機、電子信箱、備用信箱"
          allowClear
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onSearch={async () => {
            let {total, results} = await fetchRecords(keyword, columns, {
              offset: 0,
              limit: pagination.pageSize,
            });
            setRecords(results);
            setPagination({...pagination, total});
          }}
        />

        <Table
          style={{marginBottom: 10}}
          scroll={{x: 500, y: 430}}
          pagination={{
            ...pagination,
            onChange: async (page, pageSize) => {
              let {total, results} = await fetchRecords(keyword, columns, {
                offset: (page - 1) * pageSize,
                limit: pageSize,
              });

              setRecords(results);
              setPagination({...pagination, pageSize, total});
            },
          }}
          columns={columns}
          dataSource={records}
        />
      </ModalWrapper>
    </Modal>
  );
}

const ModalWrapper = styled(ModalCustomStyle)``;
