import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import {showAdminSearchCompanyModal} from '../../Components/AdminSearchCompanyModal/index.js';

export default function AdminSelectCompanyWidget(props) {
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  //rjsf write "readOnly", but props get "readonly".
  const readonly = props.readonly === true || props.readOnly === true;

  const fetchCompanyById = React.useCallback(async (id) => {
    AppActions.setLoading(true);
    try {
      setSelectedCompany(
        await JStorage.fetchOneDocument('Company', {_id: {$oid: id}}),
      );
    } catch (err) {
      console.warn(err);
    } finally {
      AppActions.setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    if (props.value) {
      fetchCompanyById(props.value);
    }
  }, [props.value, fetchCompanyById]);

  return (
    <Wrapper>
      <div className="info">
        {!props.value ? (
          '尚未設定事務所'
        ) : !selectedCompany ? (
          props.value
        ) : (
          <div>
            <Button
              type="link"
              title="查看詳情"
              style={{paddingLeft: 0, fontSize: '0.9rem', fontWeight: '400'}}
              href={`/admin/companies/?action=detail&id=${selectedCompany.id}`}>
              {selectedCompany.CONAME}
            </Button>
            <div>
              地址：{selectedCompany.address_zip_code}
              {selectedCompany.address_city}
              {selectedCompany.address_detail}
            </div>
            <div>電話：{selectedCompany.COPHONE1 || '無'}</div>
            <div>傳真：{selectedCompany.COFAX1 || '無'}</div>
          </div>
        )}
      </div>
      {!readonly && (
        <div>
          <Button
            onClick={() => {
              props.onChange('');
              setSelectedCompany(null);
            }}
            style={{marginRight: 10}}>
            清除
          </Button>
          <Button
            type="primary"
            onClick={() =>
              showAdminSearchCompanyModal({
                onChange: (id) => {
                  props.onChange(id);
                },
              })
            }>
            搜尋
          </Button>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  border: 1px solid var(--adminBorderColor);
  border-radius: 4px;
  padding: 15px;

  & > .info {
    margin-right: 15px;
  }
`;
